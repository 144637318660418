.card-title {
  font-size: 14px;
}
.logout-button {
  margin-left: auto !important;
}
body {
  background-color: #fafafa;
}
.MuiFormControl-root {
  margin-bottom: 16px !important;
}
.page-content {
  min-height: 80vh;
}